import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import ProfileView from "@/views/ProfileView.vue";
import EsimDetails from "@/views/EsimDetails.vue";
import SupportView from "@/views/SupportView.vue";
import SearchView from "@/views/SearchView.vue";
import BundleDetails from "@/views/BundleDetails.vue";
import { useUserStore } from "@/stores/user";
import { useAppStore } from "@/stores/app";
import PaymentView from "@/views/PaymentView.vue";
import { requestedCountriesFromUrl } from "@/helpers";
import PaymentDisabledView from "@/views/PaymentDisabledView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/profile",
    name: "profile",
    component: ProfileView,
    meta: {
      needAuth: true,
      title: "My profile",
      description: "My profile",
    },
  },
  {
    path: "/esim/:id",
    name: "esim",
    component: EsimDetails,
    props: true,
    meta: {
      needAuth: true,
    },
  },
  {
    path: "/support",
    name: "support",
    component: SupportView,
    meta: {
      title: "Customer support",
      description: "Customer support",
    },
  },
  {
    path: "/search",
    name: "search",
    component: SearchView,
    meta: {
      title:
        "Buy an eSIM online. local, regional and global plans. 150 + countries",
      description:
        "Toosim.com  offers more than 200 eSIMs packages. Purchase and activate eSIM within 5 minutes. Prices start from 1.55$ per GB. Start saving on data roaming now",
    },
  },
  {
    path: "/bundles/:id",
    name: "bundle",
    props: true,
    component: BundleDetails,
    meta: {
      needAuth: true,
    },
  },
  {
    path: "/payment/:id",
    name: "payment",
    component: PaymentView,
    props: true,
  },
  {
    path: "/payment-disabled",
    name: "payment-disabled",
    component: PaymentDisabledView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document?.getElementById("app")?.scrollIntoView({ behavior: "smooth" });
  },
});

router.beforeEach(
  async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ): Promise<void> => {
    const appStore = useAppStore();
    const userStore = useUserStore();

    if (appStore.firstLoading) {
      await appStore.init();
    }

    const routeExist = routes.find((route) => route.name === to.name);
    if (!routeExist) {
      if (userStore.isAuth) {
        console.log(1);
        return next("/profile");
      } else {
        console.log(2);
        return next("/search");
      }
    }

    if (to.meta.needAuth) {
      if (!userStore.isAuth) {
        console.log(3);
        if (to.name === "bundle" && to.params.id) {
          return next(
            `/search?bundleId=${
              to.params.id
            }&requestedCountries=${JSON.stringify(
              requestedCountriesFromUrl().map((c) => c.iso)
            )}#auth`
          );
        } else {
          return next("/search#auth");
        }
      }
    } else if (to.meta.withoutAuth) {
      if (userStore.isAuth) {
        console.log(4);
        return next("/profile");
      }
    }

    console.log(5);
    return next();
  }
);

router.beforeResolve((to, from) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  document.title =
    typeof to.meta.title === "string" ? `${to.meta.title} | Toosim` : "Toosim";
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  document
    .querySelector('meta[name="description"]')
    .setAttribute(
      "content",
      typeof to.meta.description === "string"
        ? `${to.meta.description} | toosim.com`
        : "Buy travel esim online. Toosim Covers more than 200 destinations worldwide Cost from $1.99/GB | toosim.com"
    );
});

export default router;
