<template>
  <div class="middle payment">
    <SuccessPayment
      v-if="transaction?.status === TransactionStatus.payed"
      :transaction="transaction"
    />
    <EsimErrorPayment
      v-else-if="transaction?.status === TransactionStatus.payedWithSimFail"
    />
    <FailPayment v-else-if="transaction?.status === TransactionStatus.failed" />
    <WaitPayment v-else />
  </div>
</template>

<style lang="sass" scoped>
.payment
    display: flex
    flex-direction: column
    align-items: center
    padding: 30px
</style>

<script setup lang="ts">
import { defineProps, onMounted, ref, Ref } from "vue";
import SuccessPayment from "@/components/payment/SuccessPayment.vue";
import { Transaction, TransactionStatus, useEsimStore } from "@/stores/esim";
import EsimErrorPayment from "@/components/payment/EsimErrorPayment.vue";
import FailPayment from "@/components/payment/FailPayment.vue";
import WaitPayment from "@/components/payment/WaitPayment.vue";
import router from "@/router";

const props = defineProps({
  id: String,
});

const checkInterval: Ref<number> = ref(0);
const esimStore = useEsimStore();
const transaction: Ref<Transaction | undefined> = ref();

async function checkTransaction(): Promise<void> {
  const transactionId: number | undefined = parseInt(String(props.id));
  if (!transactionId) return;

  transaction.value = await esimStore.getTransaction(transactionId);
  if (!transaction.value) {
    stopCheckInterval();
    await router.push("/search");
    return;
  }

  if (transaction.value.status !== TransactionStatus.created) {
    stopCheckInterval();
    return;
  }
}

function startCheckInterval(): void {
  stopCheckInterval();
  checkInterval.value = setInterval(checkTransaction, 5000);
}

function stopCheckInterval(): void {
  clearInterval(checkInterval.value);
  checkInterval.value = 0;
}

onMounted(() => {
  checkTransaction();
  startCheckInterval();
});
</script>
