<template>
  <div class="nav-mobile">
    <ul>
      <li :class="{ active: currentPath === '/search' }">
        <a @click="goSearch">
          <figure>
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.75 3C2.75 2.58579 3.08579 2.25 3.5 2.25H6.5C6.87018 2.25 7.18499 2.52008 7.24128 2.88596L7.45113 4.25H21.5C21.7353 4.25 21.957 4.36047 22.0988 4.54835C22.2405 4.73624 22.2858 4.97975 22.2211 5.20604L20.2211 12.206C20.1291 12.528 19.8349 12.75 19.5 12.75H8.75882L9.14344 15.25H20.5C20.9142 15.25 21.25 15.5858 21.25 16C21.25 16.4142 20.9142 16.75 20.5 16.75H8.5C8.12982 16.75 7.81501 16.4799 7.75872 16.114L7.15978 12.2209C7.13886 12.1529 7.12734 12.0808 7.12674 12.0061L6.08288 5.22107C6.06191 5.153 6.05038 5.0808 6.04979 5.00599L5.85656 3.75H3.5C3.08579 3.75 2.75 3.41421 2.75 3ZM7.6819 5.75L8.52805 11.25H18.9343L20.5057 5.75H7.6819ZM8.5 19.75C8.36193 19.75 8.25 19.8619 8.25 20C8.25 20.1381 8.36193 20.25 8.5 20.25C8.63807 20.25 8.75 20.1381 8.75 20C8.75 19.8619 8.63807 19.75 8.5 19.75ZM6.75 20C6.75 19.0335 7.5335 18.25 8.5 18.25C9.4665 18.25 10.25 19.0335 10.25 20C10.25 20.9665 9.4665 21.75 8.5 21.75C7.5335 21.75 6.75 20.9665 6.75 20ZM19.5 19.75C19.3619 19.75 19.25 19.8619 19.25 20C19.25 20.1381 19.3619 20.25 19.5 20.25C19.6381 20.25 19.75 20.1381 19.75 20C19.75 19.8619 19.6381 19.75 19.5 19.75ZM17.75 20C17.75 19.0335 18.5335 18.25 19.5 18.25C20.4665 18.25 21.25 19.0335 21.25 20C21.25 20.9665 20.4665 21.75 19.5 21.75C18.5335 21.75 17.75 20.9665 17.75 20Z"
                fill="#B3B3B3"
              />
            </svg>
          </figure>
          Store
        </a>
      </li>
      <li :class="{ active: currentPath === '/profile' }">
        <router-link to="/profile">
          <figure>
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.4097 12.6687C14.8826 12.6687 16.8873 10.6639 16.8873 8.19102C16.8873 5.71809 14.8826 3.71338 12.4097 3.71338C9.93674 3.71338 7.93204 5.71809 7.93204 8.19102C7.93204 10.6639 9.93674 12.6687 12.4097 12.6687ZM9.09278 13.0932C9.58724 12.8946 10.1358 12.9813 10.6376 13.1603C11.1915 13.3578 11.788 13.4654 12.4097 13.4654C13.0313 13.4654 13.6279 13.3578 14.1817 13.1603C14.6836 12.9813 15.2321 12.8946 15.7266 13.0932C17.7905 13.922 19.4723 15.5013 20.4342 17.4934C21.3947 19.4828 19.5246 21.3608 17.3154 21.3608H7.50392C5.29478 21.3608 3.42465 19.4828 4.38519 17.4934C5.34702 15.5013 7.0288 13.922 9.09278 13.0932Z"
                fill="#B3B3B3"
              />
            </svg>
          </figure>
          My SIM
        </router-link>
      </li>
      <li :class="{ active: currentPath === '/support' }">
        <router-link to="/support">
          <figure>
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.5 7.2C3.5 6.0799 3.5 5.51984 3.71799 5.09202C3.90973 4.71569 4.21569 4.40973 4.59202 4.21799C5.01984 4 5.5799 4 6.7 4H18.3C19.4201 4 19.9802 4 20.408 4.21799C20.7843 4.40973 21.0903 4.71569 21.282 5.09202C21.5 5.51984 21.5 6.0799 21.5 7.2V20L18.1757 18.3378C17.9237 18.2118 17.7977 18.1488 17.6656 18.1044C17.5484 18.065 17.4277 18.0365 17.3052 18.0193C17.1672 18 17.0263 18 16.7446 18H6.7C5.5799 18 5.01984 18 4.59202 17.782C4.21569 17.5903 3.90973 17.2843 3.71799 16.908C3.5 16.4802 3.5 15.9201 3.5 14.8V7.2Z"
                fill="#B3B3B3"
              />
            </svg>
          </figure>
          Support
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import router from "@/router";
import { computed, ComputedRef, nextTick } from "vue";
import { requestedCountriesFromUrl } from "@/helpers";
import { Bundle, useBundleStore } from "@/stores/bundle";

const currentPath: ComputedRef<string> = computed(
  () => router.currentRoute.value.path
);

const bundleStore = useBundleStore();

async function goSearch(): Promise<void> {
  bundleStore.clearBundles();
  router.push("/search");
}
</script>

<style scoped></style>
