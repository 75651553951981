<template>
  <div class="bundle-info-popup">
    <MainLoader v-if="!error" primary />
    <p class="bundle-info-popup__title">
      {{
        error
          ? "Error occurred. This bundle is no longer available. Please try again"
          : "Proceeding to Stripe"
      }}
    </p>
    <button
      v-if="error"
      class="btn btn-b btn-max btn-danger"
      @click="goSearch"
      :disabled="bundlesLoading"
    >
      Go to search
      <MainLoader v-if="bundlesLoading" />
    </button>
  </div>
</template>

<script setup lang="ts">
import { defineProps, nextTick, ref, Ref } from "vue";
import MainLoader from "@/components/MainLoader.vue";
import { Bundle, useBundleStore } from "@/stores/bundle";
import router from "@/router";
import { requestedCountriesFromUrl } from "@/helpers";

defineProps({
  error: {
    type: Boolean,
    default: false,
  },
});

const bundleStore = useBundleStore();
const bundlesLoading: Ref<boolean> = ref(false);

async function goSearch(): Promise<void> {
  bundlesLoading.value = true;
  bundleStore.countries = requestedCountriesFromUrl();
  const newBundles: Bundle[] = await bundleStore.getBundles(
    bundleStore.duration || 1,
    bundleStore.countries
  );
  await nextTick(() => {
    bundleStore.bundles = newBundles;
    router.push("/search");
    bundlesLoading.value = false;
  });
}
</script>

<style scoped lang="sass">
.bundle-info-popup
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 9999999
  background-color: #fff
  backdrop-filter: blur(10px)
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  overflow-y: auto
  padding: 40px
  gap: 30px

  &__title
    color: #000
    font-size: 18px
    font-weight: 600
    text-align: center
</style>
