<template>
  <form @submit.prevent="confirm">
    <p>
      We sent verification code to your e-mail. Please check it and type the
      code below
    </p>
    <div class="number-code">
      <v-otp-input
        ref="otpInput"
        input-classes="otp-input"
        :conditionalClass="['one', 'two', 'three', 'four']"
        separator=""
        inputType="numeric"
        :num-inputs="4"
        v-model:value="code"
        :should-auto-focus="true"
        :should-focus-order="true"
      />
    </div>
    <p class="error-text" v-if="codeInvalid">Invalid Code. Please try again</p>
    <button
      class="btn btn-b btn-danger d-block"
      type="submit"
      :disabled="code.length < 4 || codeConfirmLoading || codeInvalid"
    >
      Confirm
      <MainLoader v-if="codeConfirmLoading" />
    </button>
    <div class="btn-group-main">
      <button class="btn btn-b btn-danger btn-back" @click="goBack">
        Back
      </button>
      <button
        class="timer"
        @click="resentCode"
        :disabled="resentCodeTime > 0 || resentCodeLoading"
      >
        {{
          resentCodeTime > 0
            ? `00:${
                resentCodeTime < 10 ? `0${resentCodeTime}` : resentCodeTime
              }`
            : "Resent"
        }}
        <MainLoader v-if="resentCodeLoading" primary />
      </button>
    </div>
  </form>
</template>

<script setup lang="ts">
import { ref, Ref, watch } from "vue";
import { defineEmits } from "vue/dist/vue";
import { User, useUserStore } from "@/stores/user";
import MainLoader from "@/components/MainLoader.vue";
import { ErrorType } from "@/query";
import VOtpInput from "vue3-otp-input";
import router from "@/router";
import { useAppStore } from "@/stores/app";
import { requestedCountriesFromUrl } from "@/helpers";
import { Country } from "@/stores/data";

const emit = defineEmits(["back"]);
const goBack = () => emit("back");

const userStore = useUserStore();
const appStore = useAppStore();

const codeConfirmLoading: Ref<boolean> = ref(false);
const codeInvalid: Ref<boolean> = ref(false);
const resentCodeTime: Ref<number> = ref(59);
const resentCodeTimer: Ref<number> = ref(0);
const resentCodeLoading: Ref<boolean> = ref(false);

const otpInput = ref<InstanceType<typeof VOtpInput> | null>(null);
const code: Ref<string> = ref("");

function decrementCodeTime(): void {
  if (resentCodeTime.value <= 0) {
    stopCodeTimer();
  } else {
    resentCodeTime.value--;
  }
}

const stopCodeTimer = (): void => clearTimeout(resentCodeTimer.value);

function startCodeTimer(): void {
  stopCodeTimer();
  resentCodeTime.value = 59;
  resentCodeTimer.value = setInterval(decrementCodeTime, 1000);
}

startCodeTimer();

async function resentCode(): Promise<void> {
  resentCodeLoading.value = true;
  await userStore.login();
  startCodeTimer();
  resentCodeLoading.value = false;
}

async function confirm(): Promise<void> {
  codeConfirmLoading.value = true;
  const response: User | ErrorType | null = await userStore.validate(
    code.value
  );
  if (response in ErrorType && ErrorType[response] === ErrorType.codeInvalid) {
    codeInvalid.value = true;
  } else {
    try {
      const bundleId = router.currentRoute.value.query.bundleId;
      if (typeof bundleId === "string" && bundleId !== "null") {
        await router.push(
          `/bundles/${bundleId}?requestedCountries=${JSON.stringify(
            requestedCountriesFromUrl().map((c: Country) => c.iso)
          )}`
        );
      } else {
        await router.push("/profile");
      }
    } catch (e) {
      await router.push("/profile");
    }
    appStore.closePopup();
  }
  codeConfirmLoading.value = false;
}

watch(code, () => (codeInvalid.value = false));
</script>
