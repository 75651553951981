<template>
  <div class="middle">
    <div class="technical-support-group">
      <div class="container">
        <div class="row">
          <div class="col-lg-8">
            <div class="description-tarif description-tarif-padding">
              <h6>Instruction for eSIM activation</h6>
              <div
                v-for="(faqItem, index) in dataStore.faqItems"
                :key="index"
                class="technical-link"
              >
                <p
                  :class="{ open: faqItem.opened }"
                  @click="faqItem.opened = !faqItem.opened"
                >
                  {{ faqItem.question }}
                </p>
                <div
                  class="technical-support-info"
                  :style="`display: ${faqItem.opened ? 'block' : 'none'};`"
                >
                  <p>{{ faqItem.answer }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div v-if="questionSent" class="thank-you">
              <figure>
                <svg
                  width="68"
                  height="68"
                  viewBox="0 0 68 68"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="34"
                    cy="34"
                    r="32.5"
                    stroke="#552DEB"
                    stroke-width="3"
                  />
                  <path
                    d="M25 32.8947L32.7 41L46 27"
                    stroke="#552DEB"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </figure>
              <h5>Thank you</h5>
              <p>
                We have received your message. We will get back to you as soon
                as possible.
              </p>
              <i class="off-you" @click="questionSent = false"></i>
            </div>
            <div v-else-if="userStore.isAuth" class="form-support">
              <h6>Contact our support team</h6>
              <a
                v-if="dataStore.whatsappLink"
                class="whatsapp-button"
                :href="dataStore.whatsappLink"
                target="_blank"
              >
                <WhatsappIcon class="whatsapp-button__icon" />
                Chat on WhatsApp
              </a>
              <p>
                Contact us on WhatsApp for quick, efficient support and reliable
                communication.
              </p>
              <button
                class="button-link text-decoration-underline mb-3"
                @click="showEmailForm = true"
              >
                Get support by email
              </button>
              <p v-if="showEmailForm">
                Email us for support. Please note, replies may sometimes land in
                your spam folder
              </p>
              <form v-if="showEmailForm" @submit.prevent="sendSupport">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Name"
                    v-model="firstName"
                  />
                  <i class="off-txt" @click="firstName = ''"></i>
                </div>
                <div class="input-group">
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Email"
                    v-model="email"
                  />
                  <i class="off-txt" @click="email = ''"></i>
                </div>
                <div class="input-group">
                  <textarea
                    class="form-control"
                    placeholder="Message"
                    v-model="question"
                  ></textarea>
                </div>
                <button
                  class="btn btn-b btn-danger"
                  type="submit"
                  :disabled="formDisabled"
                >
                  Send a question
                  <MainLoader v-if="questionLoading" />
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useDataStore } from "@/stores/data";
import { computed, ComputedRef, ref, Ref } from "vue";
import { checkEmailIsValid } from "@/helpers";
import MainLoader from "@/components/MainLoader.vue";
import { useUserStore } from "@/stores/user";
import WhatsappIcon from "@/components/icons/WhatsappIcon.vue";

const dataStore = useDataStore();
const userStore = useUserStore();

const email: Ref<string> = ref("");
const firstName: Ref<string> = ref("");
const question: Ref<string> = ref("");
const questionLoading: Ref<boolean> = ref(false);
const questionSent: Ref<boolean> = ref(false);
const showEmailForm: Ref<boolean> = ref(false);

async function sendSupport() {
  questionLoading.value = true;
  await dataStore.sendSupport(firstName.value, email.value, question.value);
  questionSent.value = true;
  firstName.value = "";
  email.value = "";
  question.value = "";
  questionLoading.value = false;
}

const formDisabled: ComputedRef<boolean> = computed(
  () =>
    !checkEmailIsValid(email.value) ||
    !firstName.value.length ||
    question.value.trim().length < 10 ||
    questionLoading.value
);
</script>

<style scoped lang="sass">
.whatsapp-button
  display: flex
  align-items: center
  gap: 10px
  font-size: 18px
  color: #fff
  font-weight: 500
  padding: 10px 16px
  border-radius: 10px
  background-color: rgb(0, 230, 118)
  border: none
  outline: none
  cursor: pointer
  margin: 15px 0
  text-decoration: none

  &__icon
    height: 30px
    width: auto
</style>
