<template>
  <div v-if="!bundle && !bundleInfoError" class="middle">
    <div class="container">
      <div class="loader-container">
        <MainLoader primary />
      </div>
    </div>
  </div>
  <div v-else class="middle">
    <Transition name="fade">
      <PopupBundleInfoLoading
        v-if="buyLoading || bundleInfoError"
        :error="bundleInfoError"
      />
    </Transition>
    <div v-if="bundle" class="container">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/search">eSIM Store</router-link>
          </li>
          <li class="breadcrumb-item active" aria-current="page">
            Plan {{ bundle.dataAmountGb() }}gb {{ bundle.duration }} days
          </li>
        </ol>
      </nav>
    </div>
    <div v-if="bundle" class="description-tarif-group">
      <div class="container">
        <div class="description-tarif">
          <div class="description-tarif-head">
            <div class="group-logo-tarif">
              <figure class="logo-image">
                <a href="#">
                  <img src="@/assets/images/logo-tarif.png" alt="" />
                </a>
              </figure>
              <div class="data-tarif-item">
                <div class="gb-info-tarif">
                  {{ bundle.dataAmountGb() }} GB
                  <span>{{ bundle.duration }} days</span>
                </div>
                <BundleCountries
                  :countries="bundle.countries"
                  :requested-countries="requestedCountriesFromUrl()"
                  view-button
                />
              </div>
            </div>
            <div class="prise-tarif">
              <span class="price-main">$ {{ bundle.priceUsd() }}</span>
            </div>
          </div>
          <div class="description-tarif-content">
            <div
              class="description-tarif-content-item"
              :class="{ active: countriesOpened }"
            >
              <h6 @click="countriesOpened = !countriesOpened">
                Supported countries
              </h6>
              <div class="tarif-content">
                <span
                  v-for="(country, index) in bundle.countries"
                  :key="index"
                  :style="
                    requestedCountriesFromUrl()
                      .map((c) => c.iso)
                      .includes(country.iso)
                      ? 'font-weight: bold'
                      : ''
                  "
                >
                  {{
                    `${country.name}${
                      bundle.countries.length > 1 &&
                      index < bundle.countries.length - 1
                        ? ", "
                        : ""
                    }`
                  }}
                </span>
              </div>
            </div>
            <div
              class="description-tarif-content-item"
              :class="{ active: detailsOpened }"
            >
              <h6 @click="detailsOpened = !detailsOpened">
                Terms & Conditions
              </h6>
              <div class="tarif-content">
                <p v-html="dataStore.termsText"></p>
              </div>
            </div>
          </div>
          <div class="description-tarif-bot">
            <div class="description-tarif-bot-item">
              <div class="checkbox-group">
                <input
                  v-model="compatibilityConfirm"
                  type="checkbox"
                  id="check"
                />
                <label for="check">
                  <span>
                    I confirm my device is eSIM
                    <button
                      class="button-link"
                      @click="appStore.showPopup({ component: PopupDevices })"
                    >
                      compatible
                    </button>
                  </span>
                </label>
              </div>
            </div>
            <div class="description-tarif-bot-item">
              <div class="group-tarif-bot-item">
                <button
                  class="btn btn-b btn-max btn-danger"
                  @click="buyBundle"
                  :disabled="!compatibilityConfirm"
                >
                  <span
                    style="
                      display: flex;
                      align-items: center;
                      flex-direction: column;
                      gap: 5px;
                    "
                  >
                    <span
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 5px;
                      "
                    >
                      <span
                        :style="
                          esimStore.useCashback || esimStore.activePromo
                            ? 'text-decoration: line-through; transition: 0.2s;'
                            : 'transition: 0.2s;'
                        "
                      >
                        ${{ bundle.priceUsd() }}
                      </span>
                      <Transition name="fadeHeight">
                        <span v-if="esimStore.activePromo">
                          ${{
                            (
                              Math.round(
                                bundle.price -
                                  bundle.price *
                                    esimStore.activePromo.discountPercent
                              ) / 100
                            ).toLocaleString("en-US")
                          }}
                        </span>
                        <span v-else-if="esimStore.useCashback">
                          ${{
                            (bundlePriceWithCashback / 100).toLocaleString(
                              "en-US"
                            )
                          }}
                        </span>
                      </Transition>
                      - Buy now
                    </span>
                    <span
                      v-if="
                        dataStore.cashbackEnabled &&
                        dataStore.cashbackMaxPercent > 0
                      "
                      style="font-size: 12px"
                    >
                      Get ${{
                        (
                          Math.round(
                            bundlePriceWithCashback *
                              dataStore.cashbackMaxPercent
                          ) / 100
                        ).toLocaleString("en-US")
                      }}
                      in rewards for next purchase
                    </span>
                  </span>
                </button>
              </div>
              <p class="description-tarif-bot-item__text">
                VAT may apply. Based on your location
              </p>
              <div v-if="esimStore.activePromo" class="row mt-3">
                <div style="width: auto">
                  {{
                    Math.round(esimStore.activePromo?.discountPercent * 100)
                  }}% discount applied
                </div>
                <button
                  @click="esimStore.activePromo = null"
                  class="text-button"
                  style="color: #330fbd; width: auto"
                >
                  remove
                </button>
              </div>
              <div v-else-if="esimStore.useCashback" class="row mt-3">
                <div style="width: auto">
                  ${{ (maxUsedCashback / 100).toLocaleString("en-US") }} in
                  rewards applied
                </div>
                <button
                  @click="esimStore.useCashback = false"
                  class="text-button"
                  style="color: #330fbd; width: auto"
                >
                  remove
                </button>
              </div>
              <button
                v-else
                @click="showPromoPopup"
                class="text-button mt-3 open-promo-popup-button"
                style="
                  text-decoration: underline;
                  color: #828282;
                  font-size: 15px;
                "
              >
                Use promo code{{ dataStore.showCashback ? " or rewards" : "" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="bundle" class="data-plans-group other-plans">
      <div class="container">
        <h5>Additional plans</h5>
        <div class="data-plans-slider">
          <BundleCard
            v-for="b in otherBundles"
            :key="b.id"
            :bundle="b"
            :requested-countries="requestedCountriesFromUrl()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  computed,
  ComputedRef,
  defineProps,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  Ref,
  watch,
} from "vue";
import { Bundle, useBundleStore } from "@/stores/bundle";
import BundleCountries from "@/components/bundle/BundleCountries.vue";
import { useDataStore } from "@/stores/data";
import BundleCard from "@/components/bundle/BundleCard.vue";
import MainLoader from "@/components/MainLoader.vue";
import { useAppStore } from "@/stores/app";
import PopupDevices from "@/components/popups/PopupDevices.vue";
import { useUserStore } from "@/stores/user";
import router from "@/router";
import { requestedCountriesFromUrl } from "@/helpers";
import { useEsimStore } from "@/stores/esim";
import PopupBundleInfoLoading from "@/components/popups/PopupBundleInfoLoading.vue";
import PopupPromo from "@/components/popups/PopupPromo.vue";
const props = defineProps({
  id: String,
});

const appStore = useAppStore();
const dataStore = useDataStore();
const bundleStore = useBundleStore();
const esimStore = useEsimStore();
const userStore = useUserStore();

const bundleId: ComputedRef<string> = computed(() => String(props.id));
const bundle: ComputedRef<Bundle | undefined> = computed(() =>
  bundleStore.bundles.find((b: Bundle) => b.id === bundleId.value)
);

const compatibilityConfirm: Ref<boolean> = ref(false);
const countriesOpened: Ref<boolean> = ref(false);
const detailsOpened: Ref<boolean> = ref(false);
const bundleInfoError: Ref<boolean> = ref(false);
const buyLoading: Ref<boolean> = ref(false);

const otherBundles: ComputedRef<Bundle[]> = computed(() => {
  const bundles = bundle.value?.otherBundles
    ? [...bundle.value?.otherBundles]
    : [];
  const currentBundleIndex = bundles.findIndex(
    (b: Bundle) => b.id === bundle.value?.id
  );
  if (currentBundleIndex >= 0) {
    bundles.splice(currentBundleIndex, 1);
  }
  return bundles;
});

const bundlePriceWithCashback: ComputedRef<number> = computed(() =>
  Math.round(
    bundle.value.price -
      (esimStore.useCashback
        ? maxUsedCashback.value
        : esimStore.activePromo
        ? bundle.value?.price * esimStore.activePromo.discountPercent
        : 0)
  )
);

const maxUsedCashback: ComputedRef<number> = computed(() => {
  const userCashback: number = userStore.cashback;

  const maxBundle: number = Math.round(
    (bundle.value.price / 100) *
      (100 - dataStore.minTransactionPriceWithCashbackPercents)
  );

  if (maxBundle > userCashback) {
    return userCashback;
  }

  return maxBundle;
});

const showPromoPopup = (): void =>
  appStore.showPopup({
    component: PopupPromo,
    props: { bundlePrice: bundle.value?.price ?? 0 },
  });

async function buyBundle(): Promise<void> {
  buyLoading.value = true;

  if (!userStore.isAuth) {
    await router.push(`/search?bundleId=${bundleId.value}#auth`);
  } else {
    const bundleInfoCheck: boolean = await bundleStore.checkBundleInfo(
      bundleId.value
    );
    if (bundleInfoCheck) {
      try {
        const url: string | null = await esimStore.buyEsim(
          bundleId.value,
          requestedCountriesFromUrl(),
          window.location.href,
          esimStore.useCashback.value,
          esimStore.activePromo?.code
        );
        if (typeof url === "string" && url !== "undefined") {
          window.open(url, "_self");
        } else {
          await router.push("/payment-disabled");
          return;
        }
      } catch (e) {
        await router.push("/payment-disabled");
        return;
      }
    } else {
      bundleInfoError.value = true;
    }
  }

  buyLoading.value = false;
}

const getBundle = async (): Promise<Bundle | null> => {
  const response = await bundleStore.getBundleById(
    bundleId.value,
    requestedCountriesFromUrl().map((c) => c.id)
  );
  console.log(!response);
  if (!response) {
    bundleInfoError.value = true;
    return;
  }
  changeMeta();
};

onBeforeUnmount(() => (esimStore.activePromo = undefined));

onMounted(async () => {
  if (!bundle.value) await getBundle();
  changeMeta();

  if (!userStore.isAuth) {
    return router.push(
      `/search?bundleId=${bundle.value?.id}&requestedCountries=${JSON.stringify(
        requestedCountriesFromUrl().map((c) => c.iso)
      )}#auth`
    );
  }
});
watch(bundleId, getBundle);

function changeMeta(): void {
  const text = `eSIM: ${
    bundle.value?.duration
  } days, ${bundle.value?.dataAmountGb()} GB, ${requestedCountriesFromUrl()
    .map((c) => c.name)
    .join(", ")} | Toosim`;
  document.title = text;
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", text);
}
</script>

<style scoped lang="sass">
.description-tarif-bot-item
  display: flex
  flex-direction: column
  align-items: center
  width: 400px
  max-width: 100%

.description-tarif-bot-item__text
  text-align: center
  font-size: 14px
  color: rgba(0, 0, 0, 0.5)
  margin-top: 10px

.switch-toggle
  width: 40px
  height: 20px
  border-radius: 100px
  background-color: #F2F2F2
  transition: 0.3s
  position: relative

  &:before
    content: ''
    position: absolute
    top: 2px
    left: 2px
    width: 16px
    height: 16px
    border-radius: 50%
    background-color: #fff
    transition: 0.3s

  &.active
    background-color: #330fbd

    &:before
      left: 22px

  &-wrapper
    cursor: pointer
    font-size: 14px
    color: #828282
    display: flex
    align-items: center
    gap: 5px
    user-select: none
    width: auto
    padding: 0

.row
  display: flex
  align-items: center
  flex-direction: row
  gap: 10px
  margin-top: 20px
</style>
