import { io } from "socket.io-client";
import { TOKEN_LOCAL_KEY, useUserStore } from "@/stores/user";

class Socket {
  socket: any = null;

  constructor() {
    this.socket = null;
  }

  async connect() {
    return new Promise(() => {
      if (this.socket) return;
      const token = localStorage.getItem(TOKEN_LOCAL_KEY);
      this.socket = io(process.env.VUE_APP_SOCKET_URL, {
        reconnectionDelayMax: 10000,
        query: { token: token },
      });

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.socket.on("cashback", (data: any) => {
        if (typeof data !== "number") return;

        const userStore = useUserStore();
        userStore.cashback = data;
      });
    });
  }

  disconnect() {
    if (!this.socket) return;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.socket.disconnect();
    this.socket = null;
  }

  send(event: any, data: any) {
    console.log("SEND SOCKET", event, data);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.socket?.emit(event, data);
  }
}

const instance = new Socket();
export default instance;
