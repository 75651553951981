<template>
  <div class="flag-group">
    <figure v-for="(country, index) in filteredCountries" :key="index">
      <img :src="getCountryImageSrc(country.iso)" :alt="country.iso" />
    </figure>
    <button v-if="viewButton" class="flag-group__view" @click="viewAll">
      View all
    </button>
    <p
      v-else-if="countries.length - filteredCountries.length"
      class="link-all-flag"
    >
      +{{ countries.length - filteredCountries.length }}
    </p>
    <p v-else-if="countries.length === 1" class="link-all-flag">only</p>
  </div>
</template>

<script setup lang="ts">
import { computed, ComputedRef, defineProps, PropType } from "vue";
import { Country } from "@/stores/data";
import { getCountryImageSrc } from "@/helpers";
import { useAppStore } from "@/stores/app";
import PopupCountries from "@/components/popups/PopupCountries.vue";

const props = defineProps({
  countries: {
    type: Array as PropType<Array<Country>>,
    required: true,
    default: () => [],
  },
  requestedCountries: {
    type: Array as PropType<Array<Country>>,
    required: true,
    default: () => [],
  },
  viewButton: {
    type: Boolean,
    default: false,
  },
});

const appStore = useAppStore();

const filteredCountries: ComputedRef<Country[]> = computed(() =>
  [
    ...(props.requestedCountries?.length ? props.requestedCountries : []),
  ].splice(0, 2)
);

function viewAll(): void {
  appStore.showPopup({
    component: PopupCountries,
    props: {
      countries: props.countries,
      requestedCountries: props.requestedCountries,
    },
  });
}
</script>

<style scoped></style>
