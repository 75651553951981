import { defineStore } from "pinia";
import query, { ErrorType, QueryMethod } from "@/query";
import { ref, Ref } from "vue";
import { Country, useDataStore } from "@/stores/data";
import { Bundle, bundleFromJson } from "@/stores/bundle";

export enum EsimType {
  active,
  archive,
}
export enum EsimStatus {
  active,
  newest,
  archive,
}

export class Esim {
  id: number;
  iccid: string;
  dataAmount: number;
  duration: number;
  operatorId: number;
  smdpAddress: string;
  matchingId: string;
  price: number;
  bundleSubId: string;
  bundleCountriesIds: number[];
  requestedCountriesIds: number[];
  planType: string;
  createdAt: Date;
  remainingData: number;
  otherBundles: Bundle[];
  activatedAt?: Date;
  isBundleExpired: boolean;
  customLabel?: string;

  constructor(
    id: number,
    iccid: string,
    dataAmount: number,
    duration: number,
    operatorId: number,
    smdpAddress: string,
    matchingId: string,
    price: number,
    bundleSubId: string,
    bundleCountriesIds: number[],
    requestedCountriesIds: number[],
    planType: string,
    createdAt: Date,
    remainingData: number,
    otherBundles: Bundle[],
    isBundleExpired: boolean,
    activatedAt?: Date,
    customLabel?: string
  ) {
    this.id = id;
    this.iccid = iccid;
    this.dataAmount = dataAmount;
    this.duration = duration;
    this.operatorId = operatorId;
    this.smdpAddress = smdpAddress;
    this.matchingId = matchingId;
    this.price = price;
    this.bundleSubId = bundleSubId;
    this.bundleCountriesIds = bundleCountriesIds;
    this.requestedCountriesIds = requestedCountriesIds;
    this.planType = planType;
    this.createdAt = createdAt;
    this.remainingData = remainingData;
    this.otherBundles = otherBundles;
    this.activatedAt = activatedAt;
    this.isBundleExpired = isBundleExpired;
    this.customLabel = customLabel;
  }

  bundleCountries = (): Country[] => {
    const dataStore = useDataStore();
    const countries: Country[] = [];

    this.bundleCountriesIds.forEach((id: number) => {
      const countryIndex: number = dataStore.countries.findIndex(
        (country: Country) => country.id === id
      );
      if (countryIndex >= 0) {
        countries.push(dataStore.countries[countryIndex]);
      }
    });

    return countries;
  };

  requestedCountries = (): Country[] => {
    const dataStore = useDataStore();
    const countries: Country[] = [];

    this.requestedCountriesIds.forEach((id: number) => {
      const countryIndex: number = dataStore.countries.findIndex(
        (country: Country) => country.id === id
      );
      if (countryIndex >= 0) {
        countries.push(dataStore.countries[countryIndex]);
      }
    });

    return countries;
  };

  type = (): EsimType =>
    this.isBundleExpired ? EsimType.archive : EsimType.active;

  status = (): EsimStatus =>
    this.type() == EsimType.archive
      ? EsimStatus.archive
      : this.activatedAt
      ? EsimStatus.active
      : EsimStatus.newest;

  dataAmountGb = (): number => Math.round(this.dataAmount / 1024);
  priceUsd = (): number => this.price / 100;
  remainingDataMb = (): number => Math.round((this.remainingData ?? 0) / 1024);

  usedDataMb = (): number => this.dataAmount - (this.remainingDataMb() ?? 0);

  expiresAt = (): Date | null =>
    this.activatedAt
      ? new Date(
          this.activatedAt.getFullYear(),
          this.activatedAt.getMonth(),
          this.activatedAt.getDate() + this.duration,
          this.activatedAt.getHours(),
          this.activatedAt.getMinutes()
        )
      : null;

  expireSoon = (): boolean => (this.expiresAt() ? true : false);
}

export enum TransactionStatus {
  created,
  payed,
  failed,
  payedWithSimFail,
  refund,
  cancel,
}

export class Transaction {
  id: number;
  sessionId: string;
  userId: number;
  status: TransactionStatus;
  price: number;
  amount: number;
  duration: number;
  operatorId: number;
  dataAmount: number;
  bundleSubId: string;
  bundleId: string;
  requestedCountriesIds: number[];
  operatorResponse: any;

  constructor(
    id: number,
    sessionId: string,
    userId: number,
    status: TransactionStatus,
    price: number,
    amount: number,
    duration: number,
    operatorId: number,
    dataAmount: number,
    bundleSubId: string,
    bundleId: string,
    requestedCountriesIds: number[],
    operatorResponse: any
  ) {
    this.id = id;
    this.sessionId = sessionId;
    this.userId = userId;
    this.status = status;
    this.price = price;
    this.amount = amount;
    this.duration = duration;
    this.operatorId = operatorId;
    this.dataAmount = dataAmount;
    this.bundleSubId = bundleSubId;
    this.bundleId = bundleId;
    this.requestedCountriesIds = requestedCountriesIds;
    this.operatorResponse = operatorResponse;
  }
}

function transactionFromJson(json: Record<any, any>): Transaction {
  const requestedCountriesIds: number[] = [];

  if (Array.isArray(json.requestedCountriesIds)) {
    json.requestedCountriesIds.forEach((id) => {
      requestedCountriesIds.push(parseInt(id) || 0);
    });
  }

  return new Transaction(
    parseInt(json.id) || 0,
    String(json.sessionId),
    parseInt(json.userId) || 0,
    parseInt(json.status) || 0,
    parseInt(json.price) || 0,
    parseInt(json.amount) || 0,
    parseInt(json.duration) || 0,
    parseInt(json.operatorId) || 0,
    parseInt(json.dataAmount) || 0,
    String(json.bundleSubId),
    String(json.bundleId),
    requestedCountriesIds,
    json.operatorResponse
  );
}

function esimFromJson(json: Record<any, any>): Esim {
  const bundleCountriesIds: number[] = [];
  const requestedCountriesIds: number[] = [];
  const otherBundles: Bundle[] = [];

  if (Array.isArray(json.bundleCountriesIds)) {
    json.bundleCountriesIds.forEach((id) => {
      bundleCountriesIds.push(parseInt(id) || 0);
    });
  }

  if (Array.isArray(json.requestedCountriesIds)) {
    json.requestedCountriesIds.forEach((id) => {
      requestedCountriesIds.push(parseInt(id) || 0);
    });
  }

  if (Array.isArray(json.otherBundles)) {
    json.otherBundles.forEach((bundle) => {
      otherBundles.push(bundleFromJson(bundle));
    });
  }

  return new Esim(
    parseInt(json.id) || 0,
    String(json.iccid),
    parseInt(json.dataAmount) || 0,
    parseInt(json.duration) || 0,
    parseInt(json.operatorId) || 0,
    String(json.smdpAddress),
    String(json.matchingId),
    parseInt(json.price),
    String(json.bundleSubId),
    bundleCountriesIds,
    requestedCountriesIds,
    String(json.planType),
    new Date(json.createdAt),
    parseInt(json.remainingData) || 0,
    otherBundles,
    json.isBundleExpired === true,
    json.activatedAt ? new Date(json.activatedAt) : undefined,
    json.customLabel ? String(json.customLabel) : undefined
  );
}

export class Promo {
  id: number;
  code: string;
  discountPercent: number;

  constructor(id: number, code: string, discountPercent: number) {
    this.id = id;
    this.code = code;
    this.discountPercent = discountPercent;
  }
}

function promoFromJson(json: Record<any, any>) {
  return new Promo(
    parseInt(json["id"]) || 0,
    json["code"].toString(),
    parseFloat(json["discountPercent"]) || 0
  );
}

export const useEsimStore = defineStore("esim", () => {
  const esims: Ref<Esim[]> = ref([]);
  const activePromo: Ref<Promo | undefined> = ref();
  const useCashback: Ref<boolean> = ref(false);

  const esimsByType = (type: EsimType): Esim[] =>
    [...esims.value].filter((esim: Esim) => esim.type() === type);

  function addEsim(esim: Esim): void {
    const esimExistIndex: number = esims.value.findIndex(
      (e: Esim) => e.id === esim.id
    );
    if (esimExistIndex >= 0) {
      esims.value[esimExistIndex] = esim;
    } else {
      esims.value.push(esim);
    }
  }

  async function getEsims(type: EsimType, first: boolean): Promise<Esim[]> {
    const response = await query({
      path: "esim/get_my",
      method: QueryMethod.GET,
      params: {
        type: type,
        offset: first ? 0 : esims.value.length,
      },
    });

    const responseEsims: Esim[] = [];

    if (Array.isArray(response)) {
      if (first) {
        clearAll(type);
      }

      response.forEach((jsonEsim) => {
        const esim: Esim = esimFromJson(jsonEsim);
        responseEsims.push(esim);
        addEsim(esim);
      });
    }

    return responseEsims;
  }

  async function getEsim(id: number): Promise<Esim | null> {
    const response = await query({
      path: "esim/get_details",
      method: QueryMethod.GET,
      params: { esimId: id },
    });
    if (!(response in ErrorType)) {
      const esim: Esim = esimFromJson(response);
      addEsim(esim);
      return esim;
    } else {
      return null;
    }
  }

  async function setEsimLabel(id: number, label: string): Promise<void> {
    await query({
      path: "esim/set_label",
      method: QueryMethod.POST,
      params: {
        esimId: id,
        label,
      },
    });
  }

  async function checkPromoIsValid(code: string): Promise<Promo | null> {
    const response = await query({
      path: "promo/check",
      method: QueryMethod.GET,
      params: { promoCode: code },
    });

    if (!response || !response.promo) return null;

    return promoFromJson(response.promo);
  }

  async function buyEsim(
    bundleId: string,
    countries: Country[],
    cancelUrl: string,
    useCashback: boolean,
    promoCode?: string | null
  ): Promise<string | null> {
    const transaction = await query({
      path: "transaction/order",
      method: QueryMethod.POST,
      params: {
        bundleId: bundleId,
        requestedCountriesIds: countries.map((c: Country) => c.id),
        cancelUrl,
        useCashback,
        promoCode,
      },
    });

    if (!transaction || transaction in ErrorType) {
      return null;
    }

    return String(transaction.url);
  }

  async function getTransaction(
    transactionId: number
  ): Promise<Transaction | undefined> {
    let transaction;

    try {
      transaction = await query({
        path: "transaction/get",
        method: QueryMethod.GET,
        params: { transactionId },
      });
      console.log(transaction);
    } catch (e) {
      return;
    }

    if (!transaction) return;

    if (transaction["error"] === true) return;

    return transactionFromJson(transaction);
  }

  function clearAll(type: EsimType | undefined): void {
    if (type !== undefined) {
      const deletingEsimIds: number[] = [];

      esims.value.forEach((esim: Esim) => {
        if (esim.type() === type) {
          deletingEsimIds.push(esim.id);
        }
      });

      deletingEsimIds.forEach((id: number) => {
        esims.value.splice(
          esims.value.findIndex((esim: Esim) => esim.id === id),
          1
        );
      });
    } else {
      esims.value = [];
    }
  }

  return {
    esims,
    activePromo,
    useCashback,
    esimsByType,
    getEsims,
    getEsim,
    buyEsim,
    clearAll,
    checkPromoIsValid,
    getTransaction,
    setEsimLabel,
  };
});
